<template>
  <div class="save-row">
    <span>{{ folder.name }}</span>

    <div class="add" v-if="!isSaved" @click="addNewArticleToFolder">
      <plus-circle-icon size="16"/>
    </div>
  </div>
</template>

<script>
import {PlusCircleIcon} from "vue-feather-icons";
import {mapActions} from "vuex";

export default {
  name: "ArticleCardSaveRow",
  components: {
    PlusCircleIcon,
  },

  props: {
    folder: Object,
    article: Object
  },

  data() {
    return {
      isSaved: false
    }
  },

  methods: {
    ...mapActions('folders', ['addArticleToFolder']),

    addNewArticleToFolder() {
      this.isSaved = true;
      this.addArticleToFolder({ folderId: this.folder.id, articleId: this.article.id });
      this.$tracking.trackEvent('addedToFolder', { folderId: this.folder.id, articleId: this.article.id });
    }

  }

}
</script>

<style lang="scss" scoped>
.save-row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .add {
    cursor: pointer;
    &:hover {
      color: #9d9d9d;
    }
  }

}
</style>
